const { remoteSelectizeOpts } = require("./selectizeHelpers");

document.addEventListener("turbolinks:load", function () {
  //Only selectize things that haven't already been selectized
  $("select,input,radio").each(function () {
    if ($(this).hasClass('selectize') && !$(this).hasClass('selectized') && this.selectize === undefined) {
      $(this).selectize($(this).data('selectize') || []);
    }
  });

  $("select.selectize-organisations:not(.selectized)").each(function () {
    $(this).selectize(remoteSelectizeOpts("/api/organisations/search/"));
  });

  $("select.selectize-contacts:not(.selectized)").each(function () {
    $(this).selectize(remoteSelectizeOpts("/api/contacts/search/"));
  });
});
