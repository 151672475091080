const { remoteSelectizeOpts, rebuildSelectize } = require("./selectizeHelpers");

document.addEventListener("turbolinks:load", function () {
  const orgSelectOpts = remoteSelectizeOpts("/api/organisations/search/");
  const contactSelectOpts = remoteSelectizeOpts("/api/contacts/search/");

  const { controller, action } = document.querySelector("body").dataset;

  if (
    controller === "agreements" &&
    ["new", "create", "edit", "update"].includes(action)
  ) {
    function toggleField(optionEl, toggleFieldEl) {
      if (
        optionEl === null ||
        (optionEl.type === "radio" && optionEl.value !== "other")
      ) {
        toggleFieldEl.classList.add("hidden");
      } else if (optionEl.type === "radio") {
        toggleFieldEl.classList.remove("hidden");
      }
    }

    const optionsFieldEl = document.querySelector(".agreement_agreement_type");
    const toggleFieldEl = document.querySelector(".agreement_other_type");
    const optionEl = document.querySelector(
      ".agreement_agreement_type input:checked"
    );

    if (optionsFieldEl && toggleFieldEl) {
      optionsFieldEl.addEventListener("click", function (e) {
        toggleField(e.target, toggleFieldEl);
      });
    }

    toggleField(optionEl, toggleFieldEl);

    const clientTypeInputs = document.querySelectorAll(
      ".agreement_client_type input.radio_buttons"
    );
    const contractorTypeInputs = document.querySelectorAll(
      ".agreement_contractor_type input.radio_buttons"
    );

    for (input of clientTypeInputs) {
      input.addEventListener("change", (e) => {
        if (e.target.defaultValue === "Organisation") {
          rebuildSelectize(
            document.querySelector("#agreement_client_id"),
            orgSelectOpts,
            "selectize-contacts",
            "selectize-organisations"
          );
        } else if (e.target.defaultValue === "Contact") {
          rebuildSelectize(
            document.querySelector("#agreement_client_id"),
            contactSelectOpts,
            "selectize-organisations",
            "selectize-contacts"
          );
        }
      });
    }

    for (input of contractorTypeInputs) {
      input.addEventListener("change", (e) => {
        if (e.target.defaultValue === "Organisation") {
          rebuildSelectize(
            document.querySelector("#agreement_contractor_id"),
            orgSelectOpts,
            "selectize-contacts",
            "selectize-organisations"
          );
        } else if (e.target.defaultValue === "Contact") {
          rebuildSelectize(
            document.querySelector("#agreement_contractor_id"),
            contactSelectOpts,
            "selectize-organisations",
            "selectize-contacts"
          );
        }
      });
    }
  }
});
