module.exports = {
  remoteSelectizeOpts: function (path) {
    return {
      valueField: "id",
      labelField: "name",
      searchField: "name",
      load: function (query, callback) {
        if (!query.length) return callback();

        // TODO: UI when nothing found?
        fetch(path + encodeURIComponent(query))
          .then((res) => res.json())
          .then((data) => callback(data))
          .catch((err) => console.log(err));
      },
    };
  },
  rebuildSelectize: function (select, selectizeOpts, classFrom, classTo) {
    select.selectize.destroy();
    select.classList.remove(classFrom);
    select.classList.add(classTo);
    const $select = $(select).selectize(selectizeOpts);
    $select[0].selectize.clear();
    $select[0].selectize.clearOptions();
  },
};
