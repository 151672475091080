// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("trix");
require("@rails/actiontext");
require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("./modules/addFields");
require("./modules/removeFields");

require("./modules/agreements");

// TODO: consider replacing selectize and related dependencies
window.$ = window.jQuery = require("jquery");
require("selectize/dist/js/standalone/selectize.min.js");
require("selectize/dist/css/selectize.css");
require("./modules/selectize");

const flatpickr = require('flatpickr');
require("flatpickr/dist/flatpickr.css");
require("./modules/flatpickr");

window.Luxon = require('luxon');

window.ClipboardJS = require("clipboard");
require("./modules/clipboard.js");

require("../stylesheets/application.sass");

document.addEventListener("turbolinks:load", function () {
  // https://github.com/basecamp/trix/issues/55
  [...document.querySelectorAll("a")].forEach((el) => {
    if (
      el.tagName === "A" &&
      !el.isContentEditable &&
      el.host !== window.location.host
    ) {
      el.setAttribute("target", "_blank");
      el.setAttribute("rel", "noopener");
      if (el.host !== "") {
        el.classList.add("external-link");
      }
    }
  });
});
