const { default: flatpickr } = require("flatpickr")

document.addEventListener("turbolinks:load", function () {

    $("input.datetime").each(function () {
        $(this).flatpickr(Object.assign({
            allowInput: true, 
            enableTime: true,
            altInput: true,
            altFormat: "j M Y h:i K"
        }, $(this).data('flatpickr')))
    })
    $("input.date").each(function () {
        $(this).flatpickr(Object.assign({
            allowInput: true,
            altInput: true,
            altFormat: "j M Y"
        }, $(this).data('flatpickr')))
    })
})